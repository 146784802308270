import { SearchPageAttribute, SearchPageOptions } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  Box,
  Typography,
  MenuItem,
  SelectChangeEvent,
  Select,
} from '@mui/material';
import * as React from 'react';
import filterIcon from '@/shared/icons/filter-icon.svg';
import sortIcon from '@/shared/icons/sort-icon.svg';
import closeIcon from '@/shared/icons/close-icon.svg';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextWithIcon from '../default-components/text-with-icon/text-with-icon';
import { IsValidTag } from './search-bar-srp';
import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { sortValues } from '@/shared/store/reducers/SRPSlice';

interface SearchFilterProps {
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SearchFilters(props: SearchFilterProps) {
  const { t } = useTranslation();
  const { field } = useSearchForm<SearchPageOptions>();
  const { field: sortField, onChange: onSortChange } =
    useSearchForm<SearchPageAttribute>('sort');
  const activeFilters = React.useMemo(() => {
    let result = 0;
    Object.entries(field)
      .map(IsValidTag(t, false))
      .forEach(amount => {
        result += amount;
      });
    return result;
  }, [field, t]);

  const onFiltreClicked = useCallback(() => {
    props.setShowFilters(props.showFilters ? false : true);
  }, [props]);

  const [open, setOpen] = useState(false);

  const onSortClicked = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const selectItems = React.useMemo(() => {
    return sortValues?.map((opt, index) => {
      return (
        <MenuItem key={index} value={opt.value}>
          {t(opt.label)}
        </MenuItem>
      );
    });
  }, [t]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onValueChanged = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      onSortChange(sortValues.find(p => p.value === event.target.value));
    },
    [onSortChange],
  );
  return (
    <>
      <Box
        width={'calc(100% + 26px)'}
        position={'relative'}
        left={'-16px'}
        height={68}
        display={'flex'}
        flexDirection={'row'}
        marginBottom={'12px'}>
        {props.showFilters ? (
          <Box
            onClick={onFiltreClicked}
            flexGrow={1}
            bgcolor={Colors.White}
            display="flex"
            flexDirection={'column'}
            justifyContent="center">
            <Box paddingLeft={2}>
              <TextWithIcon
                fontWeight={700}
                icon={closeIcon.src}
                text="Luk filtre"
                iconHeight={25}
                iconWidth={25}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              onClick={onFiltreClicked}
              flexGrow={1}
              maxWidth="50%"
              bgcolor={Colors.White}
              display="flex"
              flexDirection={'column'}
              alignItems="center"
              paddingTop={'10px'}>
              <TextWithIcon
                fontWeight={700}
                textcolor={Colors.Black}
                icon={filterIcon.src}
                text="Filtre"
                iconHeight={15}
                iconWidth={15}
              />
              <Typography
                display="flex"
                alignItems="center"
                fontSize={11}
                color="#868686">
                {t(
                  activeFilters === 1
                    ? 'SRP.ActiveFilter'
                    : 'SRP.ActiveFilters',
                  {
                    Amount: activeFilters,
                  },
                )}
              </Typography>
            </Box>
            <Box width={'1px'} />
            <Box
              onClick={onSortClicked}
              bgcolor={Colors.White}
              flexGrow={1}
              maxWidth="50%"
              display="flex"
              flexDirection={'column'}
              alignItems="center"
              paddingTop={'10px'}>
              <TextWithIcon
                fontWeight={700}
                textcolor={Colors.Black}
                icon={sortIcon.src}
                text="Sortering"
                iconHeight={15}
                iconWidth={15}
              />
              <Box display="flex">
                <Select
                  color="secondary"
                  displayEmpty
                  disableUnderline
                  variant="standard"
                  SelectDisplayProps={{
                    style: {
                      fontSize: '11px',
                      padding: 0,
                      color: '#868686',
                    },
                  }}
                  IconComponent={() => {
                    return <></>;
                  }}
                  fullWidth
                  open={open}
                  onClose={handleClose}
                  renderValue={selectedValue => {
                    return sortValues.find(p => p.value === selectedValue)
                      ?.label;
                  }}
                  value={sortField.value}
                  onChange={onValueChanged}>
                  {selectItems}
                </Select>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
