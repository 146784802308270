import { SearchPageAttribute, SearchPageFields } from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import { Colors } from '@/shared/util/colors';
import { Box, Button, Divider } from '@mui/material';
import List from '@mui/material/List';
import * as React from 'react';
import { useCallback, useState } from 'react';
import SearchSRPSection, {
  SearchMode,
  SRPSection,
} from '@/web/views/components/SRP/search-section';
import SearchFilters from './search-filters';
import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { initialSearchAttribute } from '@/shared/store/reducers/SRPSlice';
import { useToggle } from '@/shared/hooks/common/useToggle';
import { useScreenSizeContext } from '@/pages/_app';
import dynamic from 'next/dynamic';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';

const LazySearchSRPSection = dynamic(
  () => import('@/web/views/components/SRP/search-section'),
  { suspense: false, ssr: false },
);

export interface BlocksVisible {
  Category?: boolean;
  Default?: boolean;
  LimitSearchTo?: boolean;
  Equipment?: boolean;
  Color?: boolean;
  Seats?: boolean;
  Location?: boolean;
}

interface SearchContentProps {
  mode: SearchMode;
  showFilters?: boolean;
  setShowFilters?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SearchContent(props: SearchContentProps) {
  const searchAttributes = useAppSelector(
    state => state.srpReducer.searchFields,
  );

  const screenSize = useScreenSizeContext();

  const [blocksVisible, setBlocksVisible] = useState<BlocksVisible>({
    Category: true,
    Default: true,
    LimitSearchTo: true,
    Equipment: !screenSize.isMobile && props.mode == 'screen' ? true : false,
    Color: !screenSize.isMobile && props.mode == 'screen' ? true : false,
    Seats: !screenSize.isMobile && props.mode == 'screen' ? true : false,
    Location: !screenSize.isMobile && props.mode == 'screen' ? true : false,
  });

  const searchResult = useAppSelector(state => state.srpReducer.searchData);

  const category = useAppSelector(
    state => state.srpReducer.searchOptions.category,
  );
  var { onChange: onChangePriceFrom } = useSearchForm<SearchPageAttribute>(
    'priceFrom',
    undefined,
    false,
  );
  var { onChange: onChangePriceTo } = useSearchForm<SearchPageAttribute>(
    'priceTo',
    undefined,
    false,
  );
  var { onChange: onChangePricePerMonthFrom } =
    useSearchForm<SearchPageAttribute>('pricePerMonthFrom', undefined, false);
  var { onChange: onChangePricePerMonthTo } =
    useSearchForm<SearchPageAttribute>('pricePerMonthTo', undefined, false);
  const [setShouldLoadRef, shouldLoadRef] = useToggle(false);
  React.useEffect(() => {
    if (!shouldLoadRef.current) {
      setShouldLoadRef(true);
      return;
    }
    if (category.value === 'leasing-biler') {
      onChangePriceFrom(initialSearchAttribute);
      onChangePriceTo(initialSearchAttribute);
    } else {
      onChangePricePerMonthFrom(initialSearchAttribute);
      onChangePricePerMonthTo(initialSearchAttribute);
    }
  }, [
    setShouldLoadRef,
    shouldLoadRef,
    category,
    onChangePriceFrom,
    onChangePriceTo,
    onChangePricePerMonthFrom,
    onChangePricePerMonthTo,
  ]);

  const setShowFiltersFalse = useCallback(() => {
    window.scrollTo(0, 0);
    props.setShowFilters(false);
  }, [props]);
  return (
    <>
      {screenSize.isMobile && props.mode !== 'screen' && (
        <SearchFilters
          showFilters={props.showFilters}
          setShowFilters={props.setShowFilters}
        />
      )}
      {(!screenSize.isMobile || props.mode === 'screen') && (
        <Box>
          {GenerateForm(
            setBlocksVisible,
            blocksVisible,
            searchAttributes,
            props,
          )}
        </Box>
      )}
      {props.showFilters && (
        <>
          <Box marginBottom={'12px'}>
            {GenerateForm(
              setBlocksVisible,
              blocksVisible,
              searchAttributes,
              props,
            )}
          </Box>
          <Box
            width="100%"
            position="fixed"
            borderTop={`1px solid ${Colors.BorderColor}`}
            bottom={0}
            paddingY={'9px'}
            paddingX={3}
            left={0}
            bgcolor={Colors.White}>
            <Button
              sx={{
                height: 52,
                borderRadius: '10px',
                color: 'white!important',
              }}
              fullWidth
              color="info"
              onClick={setShowFiltersFalse}>
              {searchResult.totalResults !== undefined
                ? `${thousandNumberSeperator(
                    searchResult.totalResults,
                  )} resultater`
                : ''}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

function GenerateForm(
  setBlocksVisible: React.Dispatch<React.SetStateAction<BlocksVisible>>,
  blocksVisible: BlocksVisible,
  searchAttributes: SearchPageFields,
  props: SearchContentProps,
) {
  return (
    <Box
      borderRadius={'10px'}
      bgcolor={Colors.Background.TableHead}
      paddingX={'15px'}>
      <List>
        <SearchSRPSection
          first={true}
          setBlocksVisible={setBlocksVisible}
          BlocksVisible={blocksVisible}
          itemKey={'Category'}
          label={'SRP.Category'}
          mode={props.mode}
          fieldsInSection={searchAttributes.categorySection}
        />

        <SearchSRPSection
          setBlocksVisible={setBlocksVisible}
          BlocksVisible={blocksVisible}
          itemKey={'Default'}
          label={'SRP.Default'}
          mode={props.mode}
          fieldsInSection={searchAttributes.defaultSection}
        />
        {props.mode === 'screen' ? (
          <>
            <Divider />
            <Box paddingTop="7px">
              <SRPSection
                mode={props.mode}
                displayHalfWidth
                fieldsInSection={searchAttributes.limitSection}
              />
            </Box>
          </>
        ) : (
          <LazySearchSRPSection
            setBlocksVisible={setBlocksVisible}
            BlocksVisible={blocksVisible}
            itemKey={'LimitSearchTo'}
            label={'SRP.LimitSearchTo'}
            mode={props.mode}
            fieldsInSection={searchAttributes.limitSection}
          />
        )}
        <LazySearchSRPSection
          setBlocksVisible={setBlocksVisible}
          BlocksVisible={blocksVisible}
          itemKey={'Equipment'}
          label={'SRP.Equipment'}
          mode={props.mode}
          fieldsInSection={searchAttributes.equipmentSection}
        />
        <LazySearchSRPSection
          setBlocksVisible={setBlocksVisible}
          BlocksVisible={blocksVisible}
          itemKey={'Color'}
          label={'SRP.Color'}
          mode={props.mode}
          fieldsInSection={searchAttributes.colorSection}
        />
        <LazySearchSRPSection
          setBlocksVisible={setBlocksVisible}
          BlocksVisible={blocksVisible}
          itemKey={'Seats'}
          label={'SRP.Seats'}
          mode={props.mode}
          fieldsInSection={searchAttributes.seatSection}
        />
        {/* {props.mode === 'screen' ? (
          <>
            <Divider />
            <Box paddingTop="7px">
              <SRPSection
                mode={props.mode}
                displayHalfWidth
                fieldsInSection={searchAttributes.locationSection}
              />
            </Box>
          </>
        ) : ( */}
        <LazySearchSRPSection
          setBlocksVisible={setBlocksVisible}
          BlocksVisible={blocksVisible}
          itemKey={'Location'}
          label={'SRP.Location'}
          mode={props.mode}
          displayHalfWidth
          fieldsInSection={searchAttributes.locationSection}
        />
        {/* )} */}
      </List>
    </Box>
  );
}
